<template>
    <div class="card">
      <div class="MatcCardHeader">
        <p>Billing Address</p>
        <button
          class="edit-address-button icon-button"
          :disabled="isProcessing"
          @click="$emit('update-billing-address')"
          :aria-label="isProcessing ? 'Updating address' : 'Edit billing address'"
        >
          <span v-if="isProcessing" class="spinner"></span>
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            class="edit-icon"
            viewBox="0 0 24 24"
            fill="currentColor"
            aria-hidden="true"
          >
            <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04a1.003 1.003 0 000-1.42l-2.34-2.34a1.003 1.003 0 00-1.42 0l-1.83 1.83 3.75 3.75 1.84-1.82z" />
          </svg>
        </button>
      </div>
      <div class="card-content">
        <div class="address-box">
          <div class="address-info">
            <p class="address-name">{{ customer.name }}</p>
            <p class="address-line">{{ customer.address.line1 }}</p>
            <p v-if="customer.address.line2" class="address-line">
              {{ customer.address.line2 }}
            </p>
            <p class="address-line">
              {{ customer.address.city }}, {{ customer.address.state }}
              {{ customer.address.postal_code }}
            </p>
            <p class="address-line">{{ countryName }}</p>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "BillingAddressCard",
    props: {
      customer: {
        type: Object,
        required: true,
      },
      isProcessing: {
        type: Boolean,
        required: true,
      },
    },
    computed: {
      countryName() {
        const countries = {
          AF: "Afghanistan",
          AL: "Albania",
          DZ: "Algeria",
          // ... (Add all necessary country codes and names)
          ES: "Spain",
          US: "United States",
          CA: "Canada",
          // Example entries; ensure to include all relevant countries
        };
        return countries[this.customer.address.country] || this.customer.address.country;
      },
    },
  };
  </script>
  
  <style>
  .MatcCardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .address-box {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .address-info {
    flex-grow: 1;
  }
  
  .address-name {
    font-weight: bold;
    font-size: 1.1rem;
    margin: 0 0 0.5rem 0;
  }
  
  .address-line {
    margin: 0.25rem 0;
    color: var(--text-color);
    font-size: 14px;
  }
  
  .icon-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .icon-button:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
  
  .edit-icon {
    width: 24px;
    height: 24px;
    color: var(--primary-color);
  }
  
  .spinner {
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-top: 2px solid var(--primary-color);
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 0.6s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  .edit-address-button {
    /* Additional styles if needed */
  }
  
  /* Tooltip Styling */
  .icon-button:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
  
  .tooltip {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    position: absolute;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 6px 8px;
    border-radius: 4px;
    white-space: nowrap;
    font-size: 12px;
    z-index: 1000;
  }
  
  .tooltip::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
  }
  
  .icon-button {
    position: relative;
  }
  </style>
  