<template>
  <div class="marketplace-container">
    <MarketplaceHeader/>
    <div class="marketplace-body">
      <div v-if="user && user.id && false"
        class="sidebar-container"
        :class="{ 'sidebar--collapsed': isSidebarCollapsed }"
      >
        <MarketplaceSidebar
          :isSidebarCollapsed="isSidebarCollapsed"
          @toggleSidebar="toggleSidebar"
        />
      </div>
      <div class="content-container">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<style scoped>
  @import "../../scss/vars.scss";
  @import "../../scss/icons.scss";
  @import "../../scss/buttons.scss";
  @import "../../scss/flex.scss";
  @import "../../scss/scroll.scss";
  @import "../../scss/marketplace.scss";
  @import "../../scss/layout.scss";
  @import "../../scss/tabs.scss";
</style>


<script>
import MarketplaceHeader from "./MarketplaceHeader.vue";
import MarketplaceSidebar from "./MarketplaceSidebar.vue";
import { mapState } from "vuex";

export default {
  components: {
    MarketplaceHeader,
    MarketplaceSidebar,
  },
  data() {
    return {
      isSidebarCollapsed: false
    };
  },
  computed: {
    ...mapState(["user"])
  },
  methods: {
    toggleSidebar() {
      this.isSidebarCollapsed = !this.isSidebarCollapsed;
    },
  }
};
</script>
