<template>
    <div class="MatcLoginPage MactMainGradient">
      <div class="MatcLoginPageDialog">
            <MarketplaceLogin 
                :hasLogo="true"            
                @login="onLogin"
                @signup="onSignUp" />
      </div>
    </div>
  </template>
  
  <style lang="scss">
    @import "../../scss/vars.scss";
    @import "../../scss/design-system.scss";
    @import "../../scss/login.scss";
    @import "../../scss/form.scss";
  </style>
  
  <script>

  import MarketplaceLogin from './MarketplaceLogin.vue';
  export default {
    name: "MarketPlaceLoginPage",
    props: [],
    data() {
      return {
      };
    },
    components: {
        MarketplaceLogin
    },
    computed: {
    },
    methods: {
        onLogin () {  
            this.$router.push("/");
        },
        onSignUp () {
            this.$router.push("/welcome.html");
        }
    },
    watch: {
    },
    async mounted() {

    },
  };
  </script>
  