<template>
  <div class="MatcUser">
    <div class="MatcCenter MatcInline">
      <div class="MatcUserImageCntr">
        <img v-if="userImage" :src="userImage" class="MatcUserImage" />
        <span v-else class="mdi mdi-account-circle MatcDefaultAvatar" style="font-size: 100px;"></span>
        <input v-if="!userImage" key="upload-input" type="file" class="MatcImageUploadFile" @change="onFileChange" />
      </div>
      <div>
        <div class="MatcFlexBar" v-if="userImage">
          <div class="MatcButton MatcButtonPrimary MatcUploadButton">
            Change
            <input key="change-input" type="file" class="MatcImageUploadFile" @change="onFileChange" />
          </div>
          <a class="MatcButton MatcButtonRedSecondary" @click="deleteImage">
            Remove
          </a>
        </div>
        <span v-else class="MatcHint">
          <div class="MatcButton MatcButtonPrimary MatcUploadButton">
            Upload
            <input key="upload-input-hint" type="file" class="MatcImageUploadFile MatcPointer" @change="onFileChange" />
          </div>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Services from "../../services/Services";

export default {
  name: "UserImage",
  props: ["user"],
  data() {
    return {
      value: this.user || {},
      files: null,
      userImage: ""
    };
  },
  computed: {
    userImageSrc() {
      return this.userImage
    },
  },
  methods: {
    onFileChange(e) {
      e.preventDefault();
      this.files = e.target.files;
      this.sendFiles();
    },
    async deleteImage() {
      try {
        await Services.getUserService().deleteImage(this.value);
        this.load();
      } catch (error) {
        console.log("Error deleting image")
      }
    },
    async sendFiles() {
      const formData = new FormData();
      for (let i = 0; i < this.files.length; i++) {
        formData.append("file", this.files[i]);
      }

      try {
        const token = Services.getUserService().getToken();
        const response = await fetch(`rest/user/${this.user.id}/images/`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });

        if (response.ok) {
          this.onUploadDone();
        } else {
          this.onUploadError();
        }
      } catch (error) {
        this.onUploadError();
      }
    },
    onUploadDone() {
      this.load();
    },
    async load() {
      try {
        let u = await Services.getUserService().loadById(this.value.id);
        this.setUser(u);
      } catch (error) {
        console.log("Error loading user")
      }
    },
    setUser(u) {
      this.value = u;
      this.userImage = u && u.image
        ? `rest/user/${u.id}/images/${u.name}_${u.lastname}/${u.image}`
        : "";
    },
    onUploadError() {
      console.log("Error uploading files")
    },
  },
  mounted() {
    this.load();
  },
};
</script>
<style>
.MatcUploadButton {
  position: relative;
  cursor: pointer;
}


.MatcUploadButton:hover {}

.MatcImageUploadFile {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.MatcUserImageCntr {
  height: 150px;
  width: 150px;
  overflow: hidden;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid rgb(209, 213, 219);
}

.MatcUserImageCntrSmall {
  height: 60px;
  width: 60px;
  overflow: hidden;
  display: inline-block;
  border-radius: 3px;
  position: relative;
}

.MatcUserImage {
  width: 100%;
  height: auto;
}

.MatcUserNoImage {
  color: #999;
}

.MatcUser .MatcImageUploadAdd {
  font-size: 100px;
  cursor: pointer;
  position: relative;
}

.MatcUser .MatcImageUploadAdd {
  font-size: 100px;
  cursor: pointer;
  position: relative;
}

.MatcUser .MatcImageUploadAdd .mdi {
  transition: all 0.2s;
  cursor: pointer;
  display: inline-block;
}

.MatcUser .MatcImageUploadAdd .mdi:hover {
  color: #92c500;
  transform: scale(1.1);
}


.MatcUserImageAnnotation {
  position: absolute;
  top: 0px;
  right: 0px;
  background: #fff;
  padding: 3px;
  opacity: 0;
  transition-duration: 0.25s;
}

.MatcUserImageCntrSmall:hover .MatcUserImageAnnotation {
  opacity: 0.7;
}
</style>