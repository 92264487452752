<template>
    <div class="icon-section account-section pop-under" :class="{ 'is-active': active }">

        <img v-if="userImage" 
            :src="userImage" 
            aria-haspopup="true" 
            aria-controls="account-menu"
            class="account-section-image" />

        <div v-else class="account-section-letters">
            {{userLetter}}
        </div>



        <div class="pop-under-wrapper account-section-content" role="menu" id="account-menu">
                <router-link class="pop-under-item" to="/settings.html">
                    <i class="fas fa-user"></i> My Account
                </router-link>
                <hr class="pop-under-divider" />
                <a class="pop-under-item " @click="logout">
                    <i class="fas fa-sign-out-alt"></i> Logout
                </a> 
        </div>
    </div>

</template>
<script>
import Services from "../../services/Services";
export default {
    name: "AccountButton",
    props: {
        userImage: {
            type: String,
            required: true, 
        },
        active: {
            type: Boolean,
            required: true,
        },
        user: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            credits: 0
        };
    },
    computed: {
        userLetter () {
            let result = ''
            if (this.user.name) {
                result += this.user.name.substring(0, 1).toUpperCase();
                if (this.user.lastname) {
                    result += this.user.lastname.substring(0, 1).toUpperCase();
                }
            } else {
                let parts = this.user.email.split('.')
                if (parts.length > 0) {
                result += parts[0].substring(0, 1).toUpperCase();
                }
                if (parts.length > 1) {
                result += parts[1].substring(0, 1).toUpperCase();
                }
            }
            return result
        },
        formattedBalance() {
            if (this.credits <= 0) {
                return "No credits"
            }
            return Math.round(this.credits / 10)
        },
    },
    methods: {
        navigate(url) {
            this.$router.push(url);
            this.isAccountDropdownActive = false;
        },
        logout() {
            Services.getUserService().logout();
            this.$emit("logout", Services.getUserService().GUEST);
            this.$router.push("/login.html");
        },
    },
    watch: {
  
    },
    mounted() {
        if (this.value) {
            this.credits = this.value
        }
    }
};
</script>