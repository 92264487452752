<template>

      <div class="MatcLoginPageContainer">
        <div class="MatcCenter" v-if="hasLogo">
          <img src="/img/logo-long-b-s.png" width="200" />
        </div>
        <div
          class="MatcToolbarTabs MatcToolbarTabsBig MatcMarginTop"
          v-if="!resetToken"
        >
          <a
            :class="{ MatcToolbarTabActive: tab === 'login' }"
            @click="setTab('login')"
            >Login</a
          >
          <a
            :class="{ MatcToolbarTabActive: tab === 'signup' }"
            @click="setTab('signup')"
            >Sign Up</a
          >
        </div>

        <div :class="'MatcLoginWrapper ' + tab">
          <div class="MatcLoginContent">
            <div class="MatcLoginPageSection">
              <div class="MatcLoginPageForm">
                <div class="luisa-form-row">
                  <label>Email</label>
                  <input
                    class="form-control input-lg"
                    placeholder="Your email"
                    type="text"
                    v-model="email"
                  />
                </div>

                <div class="luisa-form-row">
                  <label>Password</label>
                  <input
                    class="form-control input-lg"
                    placeholder="Your password"
                    type="password"
                    v-model="password"
                    @keyup.enter="login"
                  />
                </div>
              </div>
              <span
                class="MatcAlertBox MatcAlertBoxDanger MatcMarginBottom"
                v-if="errorMessage && errorMessage.length > 0"
                ><div class="MatcAlertBoxContent">{{ errorMessage }}</div></span
              >
              <div class="MatcButtonBar">
                <a
                  class="MatcButton MatcButtonPrimary MatcButtonFullWidth"
                  @click="login"
                  >Login</a
                >
              </div>
              <div class="MatcCenter">
                <a
                  class="MatcLinkButton MatcMarginTop"
                  @click="requestPasswordReset"
                  v-if="hasLoginError"
                  >Reset Password</a
                >
              </div>
            </div>
          </div>
          <!-- login -->

          <div class="MatcLoginContent">
            <div class="MatcLoginPageSection">
              <div class="MatcLoginPageForm">
                <div class="luisa-form-row">
                  <label>Email</label>
                  <input
                    class="form-control input-lg"
                    placeholder="Your email"
                    type="text"
                    v-model="email"
                  />
                </div>

                <div class="luisa-form-row">
                  <label>Password</label>
                  <input
                    class="form-control input-lg"
                    placeholder="Your password"
                    type="password"
                    v-model="password"
                    @keyup.enter="signup"
                  />
                </div>
                <div class="luisa-form-row luisa-form-row-horizontal" @click="toggleTos">
                  <label class="luisa-checkbox">
                    <input
                      type="checkbox"
                      :checked="tos"
                      @change="toggleTos"
                      aria-checked="tos"
                      aria-label="label"
                    />
                    <span class="checkbox-custom"></span>
                    <!-- <span class="checkbox-label">{{ label }}</span> -->
                  </label>
                  <span class="">
                    I accept the
                    <a
                      target="_blank"
                      style="color: var(--primary-color)"
                      href="https://www.flowrabbit.ai/privacy"
                      >privacy policy</a
                    >
                    and
                    <a
                      target="_blank"
                      style="color: var(--primary-color)"
                      href="https://www.flowrabbit.ai/terms-of-service"
                      >terms of service</a
                    >
                  </span>
                </div>
              </div>
              <span class="MatcErrorLabel">{{ errorMessage }}</span>
              <div class="MatcButtonBar">
                <a
                  class="MatcButton MatcButtonPrimary MatcButtonFullWidth"
                  @click="signup"
                  >Sign Up</a
                >
              </div>
            </div>
          </div>
          <!-- signup -->

          <div class="MatcLoginContent">
            <div class="MatcLoginPageSection" v-if="resetToken">
              <div class="MatcLoginPageForm MatcLoginPagePasswordForm">
                <div class="form-group">
                  <label>Email</label>
                  <input
                    class="form-control input-lg"
                    placeholder="Your email"
                    type="text"
                    v-model="email"
                  />
                </div>

                <div class="form-group has-feedback">
                  <label>New Password</label>
                  <input
                    class="form-control input-lg"
                    placeholder="The new password"
                    type="password"
                    v-model="password"
                  />
                </div>
              </div>
              <span
                class="MatcAlertBox MatcAlertBoxDanger MatcMarginBottom"
                v-if="errorMessage && errorMessage.length > 0"
                ><div class="MatcAlertBoxContent">{{ errorMessage }}</div></span
              >
              <div class="MatcButtonBar">
                <a class="MatcButton MatcButtonRed" @click="resetPassword"
                  >Set new password</a
                >
              </div>
            </div>
          </div>
          <!-- reset -->
        </div>
        <!-- end wrapper-->
      </div>
      <!-- Container -->
</template>

<style lang="scss">
  @import "../../scss/vars.scss";
  @import "../../scss/login.scss";
  @import "../../scss/form.scss";
  @import "../../scss/design-system.scss";
</style>

<script>
import Services from "../../services/Services";
import Logger from "../../util/Logger";
import { mapActions } from "vuex";

export default {
  name: "MarketplaceLogin",
  props: {
    hasLogo: {
      type: Boolean,
      default:true
    },
    isSignUp: {
      type: Boolean
    }
  },
  data() {
    return {
      hasLoginError: false,
      resetToken: false,
      email: "",
      password: "",
      tos: false,
      errorMessage: undefined,
      tab: "login",
      config: {},
    };
  },
  computed: {
    isQuxAuth() {
      return Services.getConfig().auth !== "keycloak";
    },
    allowSignUp() {
      return (
        this.config && this.config.user && this.config.user.allowSignUp === true
      );
    },
  },
  watch: {
    user(v) {
      Logger.log(6, "watch", "user >> " + v.email);
      this.user = v;
    },
  },
  components: {
  },
  methods: {
    ...mapActions(["setUser"]),
    setTab(tab) {
      this.tab = tab;
      this.errorMessage = "";
    },
    toggleTos () {
      this.tos = !this.tos
    },
    async resetPassword() {
      Logger.info("resetPassword", "enter ", this.email);

      if (this.email.length < 2) {
        this.errorMessage = "Please enter your email";
        this.$emit("error")
        return;
      }

      if (this.password.length < 8) {
        this.errorMessage = "Password too short. It must have at least 8 characters";
        this.$emit("error")
        return;
      }

      if (this.resetToken.length < 8) {
        this.errorMessage = "Token is wrong";
        this.$emit("error")
        return;
      }

      let result = await Services.getUserService().reset2(
        this.email,
        this.password,
        this.resetToken
      );
      if (result.type === "error") {
        this.errorMessage = "Something is wrong";
        this.$emit("error")
      } else {
        this.errorMessage = "";
        this.resetToken = "";
        this.tab = "login";
        this.$router.push("/");
      }
    },
    async requestPasswordReset() {
      Logger.info("requestPasswordReset", "enter ", this.email);
      await Services.getUserService().reset(this.email);
      this.errorMessage =
        "A link to reset your password was sent. Check your mail.";
    },
    async login() {
      Logger.log(-1, "Login.login()", "enter ", this.email);
      const result = await Services.getUserService().login({
        email: this.email,
        password: this.password,
      });
      if (result.type == "error") {
        this.$root.$emit("Error", "Wrong login credentials");
        this.errorMessage = "Login is wrong";
        this.hasLoginError = true;
        this.$emit("error")
      } else {
        this.hasLoginError = false;
        this.setUser(result);     
        this.$emit("login")      
      }
    },
    async signup() {
      Logger.info("signup", "enter ", this.email);

      if (this.password.length < 8) {
        this.errorMessage = "Password too short. It must have at least 8 characters";
        this.$emit("error")
        return;
      }

      if (this.email.indexOf('@') == -1) {
        this.errorMessage = "Please enter a valid email";
        this.$emit("error")
        return;
      }

      if (this.tos !== true) {
        this.errorMessage = "Please accept the terms of service";
        this.$emit("error")
        return;
      }

      const result = await Services.getUserService().signup({
        email: this.email,
        password: this.password,
        tos: this.tos,
        isAppStoreUser: true
      });
      if (result.type == "error") {
        if (result.errors.indexOf("user.create.domain") >= 0) {
          this.errorMessage = "Not the correct domain";
        } else if (result.errors.indexOf("user.create.nosignup") >= 0) {
          this.errorMessage = "No sign-ups allowed.";
        } else if (result.errors.indexOf("user.email.not.unique") >= 0) {
          this.errorMessage = "The Email is already taken";
        } else {
          this.errorMessage = "Password required or too short";
        }
        this.$emit("error")
      } else {
        const user = await Services.getUserService().login({
          email: this.email,
          password: this.password,
        });
        this.setUser(user);
        this.$emit("signup")       

        Logger.log(-1, "signup", "exit with login", this.email);
      }
    },
  },
  async mounted() {
    this.resetToken = this.$route.query.id;
    if (this.isSignUp) {
      this.tab = 'signup'
    }
    if (this.resetToken && this.resetToken.length > 2) {
      Logger.log(-1, "mounted", "reset ");
      this.tab = "reset";
    }
    this.config = Services.getConfig();
    Logger.log(-1, "mounted", "exit > ", this.config.user);
  },
};
</script>
