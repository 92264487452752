<template>
  <div class="applist-container">
    <MarketplaceFilters
      v-model:searchQuery="searchQuery"
      v-model:selectedTypes="selectedTypes"
    />
    <div v-if="isLoading" class="loading-container">
      <div class="loading-spinner"></div>
    </div>
    <div v-else-if="filteredApps.length === 0" class="no-apps-message">
      <p>You haven't added any apps to your favorites yet.</p>
    </div>
    <div v-else class="product-list app-list">
      <div
        v-for="app in filteredApps"
        :key="app._id"
        class="app-card"
        @click="() => goToAppPreview(app)"
      >
        <div class="favorite-icon">
          <span class="mdi mdi-star"></span>
        </div>
        <div class="app-image">
          <img
            :src="app.store?.coverImage"
            alt="App Cover"
            class="cover-image"
          />
        </div>
        <div class="app-content">
          <h3 class="app-name">{{ app.store?.name }}</h3>
          <p class="app-description">
            {{
              truncateDescription(
                app.store?.description || "No description available",
                100
              )
            }}
          </p>
        </div>
        <div class="app-details">
          <div class="badges">
            <span
              v-for="(type, typeIndex) in app.store.types"
              :key="typeIndex"
              class="badge"
            >
              {{ typeLabelMap[type] || type }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MarketplaceFilters from "./MarketplaceFilters.vue";
import Services from "../../services/Services";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    MarketplaceFilters,
  },
  data() {
    return {
      apps: [],
      searchQuery: "",
      selectedTypes: [],
      maxPrice: 100,
      selectedIntegrations: [],
      typesOptions: [
        { value: "image_generation", label: "Image Generation" },
        { value: "agent", label: "Agent" },
        { value: "text_to_speech", label: "Text to Speech" },
        { value: "transcription", label: "Transcription" },
        { value: "multimodal", label: "Multimodal" },
        { value: "chat", label: "Chat" },
      ],
      isLoading: true,
    };
  },
  methods: {
    ...mapActions(["loadUser"]),
    goToAppPreview(app) {
      this.$router.push(`/${app.appID}.html`);
    },
    truncateDescription(text, maxLength) {
      if (text.length <= maxLength) {
        return text;
      }
      return text.substring(0, maxLength) + "...";
    },
  },
  computed: {
    ...mapState(["user"]),
    typeLabelMap() {
      return this.typesOptions.reduce((map, typeOption) => {
        map[typeOption.value] = typeOption.label;
        return map;
      }, {});
    },
    filteredApps() {
      return this.apps.filter((app) => {
        const appTypes = app.store.types || [];
        const isTypeMatch =
          !this.selectedTypes ||
          this.selectedTypes.length === 0 ||
          this.selectedTypes.some((selectedType) =>
            appTypes.includes(selectedType)
          );

        return (
          isTypeMatch &&
          (!this.searchQuery ||
            app.store.name
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase()))
        );
      });
    },
  },
  async mounted() {
    try {
      const purchaseService = Services.getPurchaseService();
      const apps = await purchaseService.findApps();
      const favs = (this.user && this.user.favApps) || [];
      this.apps = apps.filter((app) => favs.includes(app.appID));
      this.filteredApps = this.apps;
    } catch (error) {
      console.error("Failed to fetch apps:", error);
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<style scoped>
@import "../../scss/marketplace.scss";
</style>
