<template>
    <div class="modal-overlay">
      <div class="modal-content">
        <form @submit.prevent="$emit('handle-address')">
          <div id="address-element"></div>
          <div class="modal-buttons">
            <button class="MatcButton" @click="$emit('close')">
              Cancel
            </button>
            <button class="MatcButton" type="submit" :disabled="isProcessing">
              <span v-if="isProcessing" class="loading-spinner"></span>
              <span v-else>Save Address</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </template>

<script>
export default {
  name: "AddressModal",
  props: {
    isProcessing: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
<style scoped>
.modal-overlay {
  /* Add your styles here */
}
.modal-content {
  /* Add your styles here */
}
</style>
