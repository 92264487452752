<template>
    <div class="icon-section credit-section pop-under"  >
        <span class="icon carot-icon-w"             
            aria-haspopup="true" 
            aria-controls="credit-menu" />
        <p>{{ formattedBalance }}</p>
        <div class="credit-section-tooltip pop-under-wrapper" id="credit-menu">
            <span class="icon carot-icon-b"></span>
            <span>
                You current balance is {{Math.round(credits / 10)}} AI credits.
            </span>

            <button class="buy-button" @click.stop="onClick">Refill</button>
        </div>
    </div>
  </template>
  <script>
  export default {
    name: "Credits",
    props: {
      value: {
        type: Number,
        required: true,
      },
      animate: {
        type: Boolean,
        required: true,
        default: false
      }
    },
    data() {
      return {
        credits:0
      };
    },
    computed: {
      formattedBalance() {
        if (this.credits <= 0) {
            return "No credits"
        }
        return Math.round(this.credits / 10)
      },
    },
    methods: {
        onClick () {
            this.$emit('show')
        },
        setCredits (newValue, frames = 30) {
            if (this.animate){
                const dif = (newValue - this.credits) / frames
                const steps = []
                for (let i= 0; i < frames; i++) {
                    steps.push(Math.round(this.credits + dif * i))
                }
                this.animateCredit(newValue,steps)
            } else {
                this.credits = newValue
            }
        },
        animateCredit(newValue, steps) {
            if (steps.length) {
                const s = steps.shift()
                this.credits = s
                requestAnimationFrame(() => {
                    this.animateCredit(newValue, steps)
                })
            } else {
                this.credits = newValue
            }
        }
    },
    watch: {
        value (v) {
            this.setCredits(v)
        }
    },
    mounted() {
        if (this.value) {
            this.credits = this.value
        }
    }
  };
  </script>