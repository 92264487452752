import AbstractService from "./AbstractService";
import Logger from "../util/Logger";

export class PurchaseService extends AbstractService {
  constructor() {
    super();
  }

  async findApps(cache=true) {
    Logger.log(1, "findApps", "enter");
    console.debug(this.cachedApps)
    if (this.cachedApps && cache) {
      return this.cachedApps
    }
    this.cachedApps = await this._get("/rest/store/apps.json");
    return this.cachedApps
  }

  findAppById(id) {
    return this._get(`/rest/store/apps/${id}.json`);
  }

  purchaseApp(data) {
    Logger.log(1, "purchaseApp", "enter", data);
    return this._post(`/rest/store/purchase/`, data);
  }

  findUserPurchases() {
    return this._get(`/rest/store/purchase/all.json`);
  }

  updatePurchase(purchaseId, data) {
    Logger.log(1, "updatePurchase", "enter", purchaseId);
    return this._post(`/rest/store/purchase/${purchaseId}.json`, data);
  }

  deletePurchase(purchaseId) {
    Logger.log(1, "deletePurchase", "enter", purchaseId);
    return this._delete(`/rest/store/purchase/${purchaseId}.json`);
  }
}
export default new PurchaseService();
