<template>
  <div
    class="marketplace-sidebar"
    :class="{ 'sidebar--collapsed': isSidebarCollapsed }"
  >
    <div class="sidebar__header">
      <a class="sidebar__toggle-btn" @click="$emit('toggleSidebar')">
        <i
          :class="
            isSidebarCollapsed
              ? 'mdi mdi-chevron-right'
              : 'mdi mdi-chevron-left'
          "
        ></i>
      </a>
    </div>
    <div class="sidebar__list">
      <ul class="sidebar__list-items">
        <li
          :class="[
            'sidebar__list-item',
            { 'sidebar__list-item--selected': isActive('/') },
          ]"
          @click="navigate('/')"
        >
          <i class="mdi mdi-apps"></i>
          <span v-if="!isSidebarCollapsed">Marketplace</span>
        </li>
        <li
          :class="[
            'sidebar__list-item',
            {
              'sidebar__list-item--selected': isActive('/favs.html'),
            },
          ]"
          @click="navigate('/favs.html')"
        >
          <i class="mdi mdi-star"></i>
          <span v-if="!isSidebarCollapsed">Favorites</span>
        </li>
      </ul>
    </div>
    <div class="sidebar__footer">
      <div
        @click="navigate('/settings.html')"
        :class="[
          'sidebar__footer-item',
          {
            'sidebar__footer-item--selected': isActive('/settings.html'),
          },
        ]"
      >
        <i class="mdi mdi-cog"></i>
        <span v-if="!isSidebarCollapsed">Settings</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["isSidebarCollapsed"],
  methods: {
    navigate(path) {
      try {
        if (this.$route.path !== path) {
          this.$router.push({ path }).catch((err) => {
            console.log(err);
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    isActive(path) {
      return this.$route.path === path;
    },
  },
};
</script>

<style scoped>
.marketplace-sidebar {
  width: 250px;
  height: calc(100% - 40px);
  background-color: #fff;
  border-right: 1px solid #e5eaf7;
  display: flex;
  position: fixed;
  flex-direction: column;
  transition: width 0.2s ease-in-out;
}
.marketplace-sidebar.sidebar--collapsed {
  width: 60px;
}

.marketplace-sidebar.sidebar--collapsed .sidebar__list-item {
  width: fit-content;
  padding: 8px;
  transition: padding 0.2s ease-in-out, width 0.2s ease-in-out;
}

.marketplace-sidebar.sidebar--collapsed .sidebar__footer {
  width: 60px;
  transition: width 0.2s ease-in-out;
}

.marketplace-sidebar.sidebar--collapsed .sidebar__footer-item {
  width: fit-content;
  padding: 8px;
  transition: padding 0.2s ease-in-out, width 0.2s ease-in-out;
}

.marketplace-sidebar.sidebar--collapsed .sidebar__list-item span,
.marketplace-sidebar.sidebar--collapsed .sidebar__footer-item span,
.marketplace-sidebar.sidebar--collapsed .sidebar__footer-org-details {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  font-size: 12px;
}

.marketplace-sidebar.sidebar--collapsed .sidebar__header-title {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.sidebar__header {
  padding: 0 16px;
  display: flex;
  align-items: center;
  height: 61px;
}

.sidebar__header-title {
  color: #181818;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  flex: 1;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  margin-right: 10px;
  font-size: 16px;
}

.sidebar__toggle-btn {
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #2a3547;
  padding: 5px;
  border-radius: 50%;
  transition: background-color 0.2s ease-in-out;
}

.sidebar__toggle-btn:hover {
  background-color: #f0f0f0;
}

.sidebar__list {
  flex: 1;
  padding: 16px;
  overflow-y: auto;
}

.sidebar__list-items {
  margin: 0;
  list-style: none;
  padding: 0;
}

.sidebar__list-item {
  margin: 0 0 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 8px 10px;
  color: #2a3547;
  border-radius: 7px;
  gap: 8px;
}

.sidebar__list-item span {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.334rem;
  display: block;
  flex: 1;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.sidebar__list-item--selected {
  font-weight: bold;
  background: var(--primary-color);
  color: white;
}

.sidebar__list-item--selected i {
  color: white;
}

.sidebar__list-item:hover {
  background-color: #f0f0f0;
}

.sidebar__footer {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #eff0f1;
  padding: 10px 16px;
  background-color: #fff;
  transition: width 0.2s ease-in-out;
}

.marketplace-sidebar.sidebar--collapsed .sidebar__footer {
  padding: 0;
  align-items: center;
}

.sidebar__footer-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 5px 0;
  transition: background-color 0.2s ease-in-out;
  padding: 8px;
  border-radius: 5px;
  gap: 8px;
}

.sidebar__footer-item i {
  font-size: 1.25rem;
  color: #2a3547;
  transition: color 0.2s ease-in-out;
}

.sidebar__footer-item span {
  font-size: 14px;
  font-weight: 400;
  color: #2a3547;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.sidebar__footer-item--selected {
  font-weight: bold;
  background: var(--primary-color);
  color: white;
}

.sidebar__footer-item--selected span,
.sidebar__footer-item--selected i {
  color: white;
}

.sidebar__footer-item:hover {
  background: #f0f0f0;
}
</style>
