<template>
    <div class="card">
      <div class="MatcCardHeader">
        <p>{{ title }}</p>
      </div>
      <div class="card-content">
        <div v-if="loading" class="loading-spinner"></div>
        <DataTable
          v-else
          :columns="columns"
          :data="data"
          :empty-message="emptyMessage"
        />
      </div>
    </div>
  </template>
<script>
import DataTable from "./DataTable.vue";

export default {
  name: "DataCard",
  components: {
    DataTable,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    emptyMessage: {
      type: String,
      default: "No data available.",
    },
  },
};
</script>
  