<template>
    <div class="modal-overlay">
      <div class="modal-content">
        <form @submit.prevent="$emit('handle-payment')">
          <div id="payment-element"></div>
          <div class="modal-buttons">
            <button class="MatcButton" @click="$emit('close')">
              Cancel
            </button>
            <button class="MatcButton" type="submit" :disabled="isProcessing">
              <span v-if="isProcessing" class="loading-spinner"></span>
              <span v-else>Confirm Payment</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </template>
<script>
export default {
  name: "PaymentModal",
  props: {
    isProcessing: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
  