<template>
  <div class="MatcMainMenu">
    <div class="MatcMainMenuSection">
      <router-link class="MatcMainMenuItem" to="/">
        <img src="/img/logo-long-w-s.png" width="120" />
      </router-link>
    </div>
    <!-- <div class="MatcMainMenuCenter">
      <div class="MatcCenter"></div>
    </div> -->
    <div class="MatcMainMenuSection">
      <Credits v-if="isUserLogged" :value="credits" :animate="true" @show="showCreditsModal"></Credits>
      <AccountButton v-if="isUserLogged" :active="isAccountDropdownActive" :user="user" :userImage="userImage"></AccountButton>
      <div v-else class="MatcMainMenuItem ">
        <div class="MatcButton MatcButtonPrimary">
        <router-link to="/login.html">Login</router-link >
        </div>
      </div>
    </div>
   
  </div>
  <AddCreditsModal
        ref="creditsDialog"
          :current-balance="credits"
          :user="user"
      />
</template>

<style lang="scss">
  @import url("../../scss/menu.scss");
  @import url("../../scss/pop-under.scss");
</style>


<script>
import { mapActions, mapState } from "vuex";
import Credits from "../components/Credits.vue";
import AccountButton from "../components/AccountButton";
import AddCreditsModal from '../components/AddCreditsModal.vue'
export default {
  name: "MarketPlaceHeader",
  data() {
    return {
      isDropdownActive: false,
      hasLanguage: false,
      isAccountDropdownActive: true,
      userImage: "",
      creditsTemp:10000,
    };
  },
  props: [""], // removed "credits" from here
  components: {
    'Credits':Credits,
    'AddCreditsModal': AddCreditsModal,
    'AccountButton': AccountButton
  },
  computed: {
    ...mapState(["user", "credits"]),
    formattedCredits() {
      return this.credits;
    },
    userImageSrc() {
      return this.userImage;
    },
    isUserLogged() {
      return this.user && this.user.id && this.user.id !== -1;
    },
  },
  methods: {
    ...mapActions(["loadUser", "getCredits"]),
    async showCreditsModal() {
      if (this.$refs.creditsDialog) {
        await this.getCredits();
        this.$refs.creditsDialog.show();
      }
    },
    goToLogin() {
      this.$router.push("/login.html");
    },
    toggleAccountDropdown() {
      this.isAccountDropdownActive = !this.isAccountDropdownActive;
    },
    navigate(url) {
      this.$router.push(url);
      this.isAccountDropdownActive = false;
    },

    closeAccountDropdown(event) {
      if (
        this.$refs.accountdropdown &&
        !this.$refs.accountdropdown.contains(event.target)
      ) {
        this.isAccountDropdownActive = false;
      }
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isDropdownActive = false;
        this.isAccountDropdownActive = false;
      }
    },
  },
  async mounted() {
    await this.loadUser();
    await this.getCredits()
    if (this.user && this.user.id) {
      this.userImage = this.user && this.user.image
      ? `/rest/user/${this.user.id}/images/${this.user.name}_${this.user.lastname}/${this.user.image}`
      : ""; 
    }
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }
};
</script>
