<template>
    <div class="mp-welcome-content MatcLoginPageContainer">
      <div :class="'MatcLoginWrapper ' + tab">
          <div class="MatcLoginContent">
            <div class="MatcLoginPageSection">
              <div class="mp-welcome-message">
                <h1>Welcome!</h1>
                <p>
                    Thanks for signing up with Flowrabit. Before you start using the apps, you need
                    to charge the AI Budget.
                </p>
              </div>
             
              <div class="MatcButtonBar">
                <a class="MatcButton MatcButtonPrimary " @click="onPromoCode">Next</a>
              </div>
            </div>
          </div>
          <!-- login -->

          <div class="MatcLoginContent">
            <div class="MatcLoginPageSection">
              <div class="mp-welcome-message">
                  
                <h1>Promo Code?</h1>
                <p>
                  Claim free AI credits and paste your promo-code it here:
                </p>

                <div class="luisa-form-row">
                  <label>Promo code</label>
                  <input
                    class="form-control input-lg"
                    placeholder="Enter your promo code here"         
                    v-model="promoCode"
                    @keyup.enter="login"
                  />
                  <a class="MatcButton MatcButtonPrimary MatcButtonFullWidth MatcMarginTop" v-if="promoCode" @click="onRedeemCode">Redeem Code</a>   
                </div>

                <div class="MatcAlertBox MatcAlertBoxDanger MatcMarginBottom"  v-if="errorMessage && errorMessage.length > 0">
                  <div class="MatcAlertBoxContent">{{ errorMessage }}</div>
                </div>

                <div class="MatcAlertBox MatcAlertBoxSuccess MatcMarginBottom"  v-if="successMessage && successMessage.length > 0">
                  <div class="MatcAlertBoxContent">{{ successMessage }}</div>
                </div>
             

              </div>
              <div class="MatcButtonBar">
                <!-- <a class="MatcLinkButton " @click="onWelcome">Back</a > -->
                <a class="MatcButton MatcButtonPrimary " @click="onOk">Let's start!</a>   
              </div>
            </div>
          </div>
      </div>
        
    </div>
</template>

<style lang="scss">
  @import "../../scss/signup.scss";
  @import "../../scss/login.scss";
  @import "../../scss/form.scss";
  @import "../../scss/design-system.scss";
</style>

<script>
import Services from "../../services/Services";
import { mapActions, mapState } from "vuex";

export default {
  name: "WelcomeMessage",
  data() {
    return {
      tab: 'login',
      promoCode: '',
      errorMessage: '',
      successMessage: ''
    };
  },
  components: {
      
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    ...mapActions(["loadUser", "updateUser", "getCredits"]),
    async onRedeemCode () {
      this.errorMessage = ''
      if (this.promoCode.length < 3) {
        this.errorMessage = 'Promo Code not found. Please try another'
        return
      }
      const result = await Services.getUserService().redeemPromoCode(this.promoCode)
      console.debug('result', result)
      if (result.errors) {
        this.errorMessage = 'Promo Code not found or expired. Please try another'
      } else {
        this.successMessage = 'Congratulations! We added the AI credits to your account!'
        this.getCredits()
      }
    },
    onWelcome () {
      this.tab = 'login'
    },
    onPromoCode () {
      this.tab = 'signup'
    },
    onOk () {
        this.$emit("close")
    }
  },
  async mounted() {
  },
};
</script>
