<template>
    <ZoomDialog ref="dialog" :closeOnBackdrop="false">
      <div class="mp-sign-up-dialog MatcFlex">
          <div class="mp-sign-up-dialog-promo ">
            <img src="/img/logo-long-w-s.png" width="300" />           
          </div>
          <div class="mp-sign-up-dialog-content">
            <WelcomeMessage @close="onClose"></WelcomeMessage>
          </div>
      </div>
    </ZoomDialog>

</template>

<style lang="scss">
  @import "../../scss/signup.scss";
  @import "../../scss/login.scss";
  @import "../../scss/form.scss";
  @import "../../scss/design-system.scss";
</style>

<script>

import ZoomDialog from "../components/ZoomDialog";
import { mapActions, mapState } from "vuex";
import WelcomeMessage from "./WelcomeMessage.vue";

export default {
  name: "SignupDialog",
  data() {
    return {
    };
  },
  components: {
      ZoomDialog,
      WelcomeMessage
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    ...mapActions(["loadUser", "updateUser"]),
    onClose () {
        this.$refs.dialog.close()
    },
    show() {
      this.$refs.dialog.show()
    },
    onError() {
      this.$refs.dialog.shake()
    }
  },
  async mounted() {
  },
};
</script>
