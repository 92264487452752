<template>
      <ZoomDialog ref="dialog" :closeOnBackdrop="page !== 'welcome'">
        <div class="mp-sign-up-dialog MatcFlex">
            <div class="mp-sign-up-dialog-promo ">
              <img src="/img/logo-long-w-s.png" width="300" />
              <div class="mp-sign-up-dialog-promo-message" v-if="page === 'login'" >
                <h1>Sign up</h1>
                <p>
                  Start using the magic Flowrabit Apps and experience the power of GenAI.
                </p>
                <p>
                  Before you start using the apps, you need to charge credits.
                </p>
              </div>
            </div>
            <div class="mp-sign-up-dialog-content">

                <MarketplaceLogin 
                  :isSignUp="true" 
                  :hasLogo="false"
                  @error="onError" 
                  @signup="onSignUp"
                  @login="onLogin" 
                  v-if="page == 'login'"/>

                <WelcomeMessage v-if="page === 'welcome'" 
                   @close="onClose" 
                />
            

            </div>
        </div>
      </ZoomDialog>

  </template>
  
<style lang="scss">
    @import "../../scss/signup.scss";
    @import "../../scss/login.scss";
    @import "../../scss/form.scss";
    @import "../../scss/design-system.scss";
</style>
  
<script>

  import ZoomDialog from "../components/ZoomDialog";
  import { mapActions, mapState } from "vuex";
  import MarketplaceLogin from "./MarketplaceLogin.vue";
  import WelcomeMessage from "./WelcomeMessage.vue";

  export default {
    name: "SignupDialog",
    data() {
      return {
        page: 'login'
      };
    },
    components: {
        ZoomDialog,
        MarketplaceLogin,
        WelcomeMessage
    },
    computed: {
      ...mapState(["user"]),
    },
    methods: {
      ...mapActions(["loadUser", "updateUser"]),
      onSignUp () {
        this.page = 'welcome'
      },
      onLogin() {
        this.$refs.dialog.close()
      },
      onClose () {
        this.$refs.dialog.close()
      },
      show(e) {
        this.page = 'login'
        this.$refs.dialog.show(e.target)
      },
      onError() {
        this.$refs.dialog.shake()
      }
    },
    async mounted() {
    },
  };
  </script>
  