<template>
  <div>
    <!-- Header Section -->
    <section class="header" v-if="showBillingAddressSection">
      <div>
        <p class="header-title">Billing Address</p>
        <p class="header-body">Manage your billing and payment information</p>
      </div>
      <button class="MatcButton" @click="updateBillingAddress">
        Update Billing Address
      </button>
    </section>

    <!-- Status Messages -->
    <div v-if="statusMessage" class="status-message">{{ statusMessage }}</div>
    <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>


      <!-- Left Column -->
      <div class="grid-column">
        <!-- Credits Card -->
        <CreditsCard
          :current-balance="currentBalance"
          :loading="loadingBalance"
          :credit-options="creditOptions"
          :is-processing="isProcessing"
          @buy-credits="initiatePayment"
        />

        <!-- Billing Address Card -->
        <BillingAddressCard
          v-if="customer && customer.address"
          :customer="customer"
          :is-processing="isProcessing"
          @update-billing-address="updateBillingAddress"
        />
      </div>


      <div class="MatcCardContentSection">
        <!-- Receipts Card -->
        <DataCard
          title="Receipts"
          :loading="loadingReceipts"
          :columns="receiptColumns"
          :data="receipts"
          empty-message="You haven't made any payments yet."
        />

      </div>
      <div class="MatcCardContentSection">
        <DataCard
          title="Invoices"
          :loading="loadingInvoices"
          :columns="invoiceColumns"
          :data="invoices"
          empty-message="You haven't received any invoices yet."
        />
      </div>


    <!-- Payment Modal -->
    <PaymentModal
      v-if="showPaymentModal"
      :is-processing="isProcessing"
      @close="closePaymentModal"
      @handle-payment="handlePayment"
    />

    <!-- Address Modal -->
    <AddressModal
      v-if="showAddressModal"
      :is-processing="isProcessing"
      @close="closeAddressModal"
      @handle-address="handleAddress"
    />
  </div>
</template>
<style scoped>
  @import "../../scss/account-billing.scss";
</style>  
<script>
import { loadStripe } from "@stripe/stripe-js/pure";
import DataCard from "../components/DataCard.vue";
import CreditsCard from "../components/CreditsCard.vue";
import BillingAddressCard from "../components/BillingAddressCard.vue";
import PaymentModal from "../components/PaymentModal.vue";
import AddressModal from "../components/AddressModal.vue";
import Services from "../../services/Services";

export default {
  components: {
    DataCard,
    CreditsCard,
    BillingAddressCard,
    PaymentModal,
    AddressModal,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // State Variables
      currentBalance: 0,
      customer: {},
      isProcessing: false,

      // Options and Configurations
      creditOptions: [
        { value: 10, label: "$10" },
        { value: 20, label: "$20" },
        { value: 50, label: "$50" },
        { value: "custom", label: "Custom" },
      ],
      receiptColumns: [
        { key: "date", label: "Date" },
        { key: "amount", label: "Amount" },
        { key: "actions", label: "" },
      ],
      invoiceColumns: [
        { key: "date", label: "Date" },
        { key: "amount", label: "Amount" },
        { key: "actions", label: "" },
      ],

      // Data Lists
      receipts: [],
      invoices: [],

      // Stripe Elements
      stripe: null,
      elements: null,
      clientSecret: "",

      // Modals
      showPaymentModal: false,
      showAddressModal: false,

      // Messages
      statusMessage: "",
      errorMessage: "",

      // Loading States
      loadingBalance: false,
      loadingReceipts: false,
      loadingInvoices: false,
    };
  },
  computed: {
    showBillingAddressSection() {
      return (
        this.customer &&
        !this.customer.address &&
        !this.loadingReceipts &&
        !this.loadingBalance &&
        !this.loadingInvoices
      );
    },
  },
  methods: {
    updateErrorMessage(message) {
      this.errorMessage = message;
    },
    closeAddressModal() {
      this.showAddressModal = false;
      if (this.elements) {
        const addressElement = this.elements.getElement("address");
        if (addressElement) {
          addressElement.destroy();
        }
        this.elements = null;
      }
    },
    async updateBillingAddress() {
      this.showAddressModal = true;
      this.$nextTick(this.setupAddressElements);
    },
    async initiatePayment(amount) {
      if (!amount) {
        this.errorMessage = "Invalid amount.";
        return;
      }
      this.isProcessing = true;
      this.statusMessage = "";
      this.errorMessage = "";

      try {
        const response = await Services.getStripeService().createPaymentIntent(
          this.user,
          amount
        );
        this.clientSecret = response.clientSecret;
        this.showPaymentModal = true;
        this.$nextTick(() => {
          this.setupPaymentElements();
        });
      } catch (error) {
        this.errorMessage = "Error creating payment intent.";
        console.error("Error creating payment intent:", error);
      } finally {
        this.isProcessing = false;
      }
    },
    closePaymentModal() {
      this.showPaymentModal = false;
      // Optionally, clean up Stripe elements if necessary
      if (this.elements) {
        this.elements = null;
      }
    },
    setupPaymentElements() {
      if (!this.stripe || !this.clientSecret) {
        this.errorMessage = "Stripe setup error. Please try again.";
        return;
      }
      this.elements = this.stripe.elements({ clientSecret: this.clientSecret });
      const paymentElement = this.elements.create("payment");
      paymentElement.mount("#payment-element");
    },
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async handlePayment() {
      if (!this.stripe || !this.clientSecret) {
        this.errorMessage = "Payment setup error. Please try again.";
        return;
      }

      this.isProcessing = true;
      this.statusMessage = "";
      this.errorMessage = "";

      const { error } = await this.stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          // Optionally, provide return_url if you want to redirect after payment
          // return_url: 'https://yourdomain.com/payment-success',
        },
        redirect: "if_required",
      });

      if (error) {
        this.errorMessage = error.message;
        console.error("Payment failed:", error.message);
        this.isProcessing = false;
      } else {
        // Delay to allow webhook processing
        await this.delay(3000); // Wait for 3 seconds
        await this.updateBalance();
        this.statusMessage = "Balance updated successfully.";
      }
    },
    async updateBalance() {
      this.loadingBalance = true;
      try {
        const budget = await Services.getStripeService().getCurrentBudget();
        this.currentBalance =
          budget.creditsInCentiCent > 0 ? budget.creditsInCentiCent / 10000 : 0;
        await this.fetchCustomerDetails();
      } catch (error) {
        this.errorMessage = "Failed to update balance.";
        console.error("Error updating balance:", error);
      } finally {
        this.loadingBalance = false;
        this.closePaymentModal();
        this.isProcessing = false;
      }
    },
    async fetchCustomerDetails() {
      this.loadingReceipts = true;
      this.loadingInvoices = true;
      try {
        const response = await Services.getStripeService().getCustomerDetails(
          this.user.id
        );

        if (response.charges) {

          this.receipts = response.charges.map((charge) => ({
            date: new Date(charge.created * 1000).toLocaleDateString(),
            amount: `$${(charge.amount / 100).toFixed(2)}`,
            actions: {
              type: "link",
              url: charge.receipt_url,
              label: "View Receipt",
            },
          }));
        }
        if (response.invoices) {
          this.invoices = response.invoices.map((invoice) => ({
            date: new Date(invoice.created * 1000).toLocaleDateString(),
            amount: `$${(invoice.total / 100).toFixed(2)}`,
            actions: {
              type: "link",
              url: invoice.invoice_pdf,
              label: "Download PDF",
            },
          }));
        }
        this.customer = response.customer;
        console.log(this.customer);
      } catch (error) {
        console.error("Error fetching customer details:", error);
        this.errorMessage = "Failed to load billing details.";
      } finally {
        this.loadingReceipts = false;
        this.loadingInvoices = false;
      }
    },
    async setupAddressElements() {
      if (!this.stripe) {
        this.errorMessage = "Stripe setup error. Please try again.";
        return;
      }

      this.addressElements = this.stripe.elements();
      const addressElement = this.addressElements.create("address", {
        mode: "billing",
        defaultValues: {
          name: this.customer.name,
          address: (this.customer && this.customer.address) || {},
        },
      });
      addressElement.mount("#address-element");
    },
    async handleAddress(event) {
      event.preventDefault();
      if (!this.stripe || !this.addressElements) {
        this.errorMessage = "Address setup error. Please try again.";
        return;
      }

      this.isProcessing = true;
      this.statusMessage = "";
      this.errorMessage = "";

      const addressElement = this.addressElements.getElement("address");
      const { complete, value: addressDetails } =
        await addressElement.getValue();

      if (complete) {
        try {
          // Update the address in Stripe
          await this.updateStripeAddress(addressDetails);
          this.statusMessage =
            "Billing address updated successfully in Stripe.";
          this.closeAddressModal();
          // Refresh customer details to reflect the new address
          await this.fetchCustomerDetails();
        } catch (error) {
          this.errorMessage =
            "Failed to update billing address. Please try again.";
          console.error("Error updating billing address in Stripe:", error);
        }
      } else {
        this.errorMessage = "Please fill in all required address fields.";
      }

      this.isProcessing = false;
    },
    async updateStripeAddress(addressDetails) {
      if (!this.user.stripeCustomerID) {
        throw new Error("Stripe Customer ID not found");
      }

      const addressData = {
        address: {
          line1: addressDetails.address.line1,
          line2: addressDetails.address.line2,
          city: addressDetails.address.city,
          state: addressDetails.address.state,
          postal_code: addressDetails.address.postal_code,
          country: addressDetails.address.country,
        },
        name: addressDetails.name,
        phone: addressDetails.phone,
      };

      // Call your backend API to update the address in Stripe
      await Services.getStripeService().updateBillingAddress(
        this.user.id,
        addressData
      );
    },
  },
  async mounted() {
    try {
      this.stripe = await loadStripe(
        "pk_test_51PoW6eIuXbKQu0JfFV1RQst8a2Q34c9ch7B3FgvQzkeo1V7joZhNqfYA78KCXF3DD2uBx121BslzWasbnrsHv6ou006tg7CTeM"
      );
      await this.updateBalance();
    } catch (error) {
      console.error("Error initializing Stripe:", error);
    }
  },
};
</script>


