<template>
  <div class="card">
    <div class="MatcCardHeader">
      <p>Credits</p>
    </div>
    <div class="card-content">
      <div class="balance-box">
        <p>Current balance</p>
        <p v-if="loading" class="loading-spinner"></p>
        <p v-else class="balance-amount">
          {{ formattedBalance }} credits
        </p>
        <p class="balance-note">
          Balance updates may take up to one hour to reflect recent usage.
        </p>
      </div>
      <div class="credit-options">
        <div
          v-for="option in creditOptions"
          :key="option.value"
          class="credit-option"
          :class="{ selected: selectedCredit === option.value }"
        >
          <input
            type="radio"
            :id="'option-' + option.value"
            :value="option.value"
            v-model="selectedCredit"
            class="credit-radio"
          />
          <label :for="'option-' + option.value" class="credit-label">
            <span class="credit-amount">{{ option.label }}</span>
          </label>
        </div>
      </div>
      <div
        v-if="selectedCredit === 'custom'"
        class="custom-input-container"
      >
        <input
          type="number"
          v-model.number="customValue"
          class="custom-input"
          placeholder="Enter custom value (min $5)"
          min="5"
          step="0.01"
          @input="validateCustomValue"
        />
      </div>
      <!-- Display error message if any -->
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
      <!-- Button to initiate payment -->
      <div class="MatcCenter">
        <button
          class="MatcButton buy-credits-button"
          :disabled="isProcessing"
          @click="initiatePayment"
        >
          <span v-if="isProcessing" class="loading-spinner"></span>
          <span v-else>Buy {{ formatPrice() }}</span>
        </button>
      </div> 
    </div>
  </div>
</template>

<script>
export default {
  name: "CreditsCard",
  props: {
    currentBalance: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    creditOptions: {
      type: Array,
      required: true,
    },
    isProcessing: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selectedCredit: 20,
      customValue: 5,
      errorMessage: "",
    };
  },
  computed: {
    formattedBalance() {
      return this.currentBalance ? this.currentBalance.toFixed(4) : "0.00";
    },
  },
  methods: {
    getSelectedAmount() {
      return this.selectedCredit === "custom" ? this.customValue : this.selectedCredit;
    },
    isValidAmount() {
      const amount = this.getSelectedAmount();
      return amount >= 5 && !isNaN(amount);
    },
    formatPrice() {
      const amount = this.getSelectedAmount();
      return `$${parseFloat(amount).toFixed(2)}`;
    },
    validateCustomValue() {
      if (this.customValue < 5) {
        this.errorMessage = "Minimum amount is $5";
      } else {
        this.errorMessage = "";
      }
    },
    initiatePayment() {
      const amount = this.getSelectedAmount();
      if (!this.isValidAmount()) {
        this.errorMessage = "Please enter a valid amount (minimum $5).";
        return;
      }
      this.errorMessage = "";
      // Emit event to parent with amount
      this.$emit('buy-credits', amount);
    },
  },
};
</script>
